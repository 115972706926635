import React from 'react';
import {Seo} from 'components';
import Layout from 'layout';

// import page style
import './index.scss';

const IndexPage = () => {
  return (
    <Layout indication="hcp" className="hcp-home">
      <Seo pageTitle="Hcp Home" />
      <div className="body-content-container">
        <div className="body-content gutter-all">
          {/** Actual content goes here */}
          HCP Content
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
